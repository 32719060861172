/* Styles */

body {
  width: 100%;
  position: fixed;
  overflow: hidden;
}

/* Date Range Picker Styles */

.dateRangePicker {
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  position: relative;
  display: inline-block;
}

.dayPicker__presetList {
  margin: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* .dayPicker__presetBtn {
  background-color: #66e2da;
  margin: 0.5em;
  height: 2.5em;
  letter-spacing: 1px;
  font-size: 12px;
} */

.dayPicker__submitList {
  margin: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* .dayPicker__submitBtn {
  color: #fcb74b;
  margin: 0.5em;
  height: 2.5em;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 600;
} */

.DateRangePicker_picker__fullScreenPortal {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.DayPicker_portal__horizontal {
  -webkit-box-shadow: none;
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.DateRangePicker_picker {
  z-index: 1002 !important;
}

.DateRangePicker-centered .DateRangePicker_picker {
  left: 50% !important;
  transform: translate(-50%, 0);
}

@media screen and (max-width: 400px) {
  .DateInput {
    width: 115px !important;
  }
}

/* End Date Range Picker Styles */

/* Cloudinary Styles */

.selectableImage {
  height: 6em;
  margin: 1em;
  cursor: pointer;
}
.selectableImage.selected {
  box-shadow: 0px 0px 2px 5px #fbb74c;
}

@media screen and (max-width: 580px) {
  #uploadImages {
    display: none;
  }
  #modalCloseX {
    display: none;
  }

  .selectableImage {
    margin: 0.5em;
  }
  .editMenuMobile__item {
    color: rgb(252, 183, 75);
    font-size: 16px;
    font-weight: 100;
  }
  #editMenuLeft {
    margin-left: 10%;
  }
  #editMenuRight {
    margin-right: 10%;
  }
}
/* END Cloudinary Styles */

/* Colors */
.color-yellow {
  color: rgba(253, 185, 76, 1);
}
.color-green {
  color: rgba(130, 234, 183, 1);
}
.color-red {
  color: rgba(239, 65, 82, 1);
}
.color-purple {
  color: rgba(104, 93, 168, 1);
}

orange {
  color: #fbb74c;
}
white {
  color: white;
}
/* End Colors */

/* Google Material Fonts */
/* Rules for sizing the icon. */

.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.pac-container {
  z-index: 1002;
}

div[x-placement="top-end"] .__floater__body {
  position: relative;
}
@media screen and (min-width: 581px) {
  div[x-placement="top-end"] .__floater__arrow {
    right: 70px !important;
    left: auto !important;
    bottom: auto !important;
  }
}
.appContainer .droppable.sortable-drag {
  border-radius: 0;
  box-shadow: none !important;
  /* background: lightgray; */
  /* padding: 20px; */
  /* Fallback dashed border
     - the 4px width here is overwritten with the border-image-width (if set)
     - the border-image-width can be omitted below if it is the same as the 4px here
  */
  border: dashed 2px #a9a7a7;

  /* Individual border image properties */
  border-image-source: url("../images/dotted-border.png");
  border-image-slice: 2;
  border-image-repeat: round;

  /* or use the shorthand border-image */
  border-image: url("../images/dotted-border.png") 2 round;
  /* '/public/dotted-border-2px-3px-10px.png' */
}
.sortable-chosen {
  /* background: red; */
}
